import "bootstrap/dist/js/bootstrap";
import enquire from "enquire.js";
import "./libs/jquery.widowFix.min";
import "dense/src/dense";
import debounce from "./utils/debounce";
import setEqualHeight from "./utils/setEqualHeight";
import notification from "./utils/notification";
import "./header-products-categories";

jQuery(window).on("load", function () {
  jQuery(window).trigger("resize");
});

jQuery(document).ready(function ($) {
  // // SVG Fallbacks
  // if (!Modernizr.svg) {
  //   $("img[src$='.svg']").each(function () {
  //     var src = $(this).attr("src");
  //     var path = src.substring(0, src.lastIndexOf("/"));
  //     var fileName = src.substring(src.lastIndexOf("/"));
  //     var newSrc = path + fileName.replace("svg", "png");
  //     $(this).attr("src", newSrc);
  //   });
  // }

  // Load Retina images
  $("img").dense({
    glue: "@",
  });

  // Set testimonial height
  if ($(".testimonial").length) {
    var testimonialEqualHeights = debounce(function () {
      $(".testimonial p").css("height", "auto"); //solve for all you browser stretchers out there!
      setEqualHeight($(".testimonial p"), true, false);
    }, 500);
    testimonialEqualHeights();
    $(window).on("load resize", function () {
      testimonialEqualHeights();
    });
  }

  // Set homepage hero bandover
  if ($(".three-cols-outer .exam-description").length) {
    var homeHeroBandoverEqualHeights = debounce(function () {
      $(".exam-description").css("height", "auto"); //solve for all you browser stretchers out there!
      setEqualHeight($(".exam-description"), true, false);
    }, 500);
    homeHeroBandoverEqualHeights();
    $(window).on("load resize", function () {
      homeHeroBandoverEqualHeights();
    });
  }

  // Set homepage second fold eqheights
  if ($(".feature-col .col-outer").length) {
    var homeSecondFoldEqualHeights = debounce(function () {
      $(".feature-col .col-outer").css("height", "auto"); //solve for all you browser stretchers out there!
      setEqualHeight($(".feature-col .col-outer"), true, false);
    }, 500);
    homeSecondFoldEqualHeights();
    $(window).on("load resize", function () {
      homeSecondFoldEqualHeights();
    });
  }

  // Set eqheights for product cards
  if ($(".product-block.product .product-name").length) {
    var productCards = debounce(function () {
      $(".product-block.product .product-name").css("height", "auto"); //solve for all you browser stretchers out there!
      setEqualHeight($(".product-block.product .product-name"), true, false);
    }, 500);
    productCards();
    $(window).on("load resize", function () {
      productCards();
    });
  }

  // Set equal heights on main nav
  // var headerEqHeights = debounce(function() {
  //   var testsGroup = $('.product-group[data-product-group="tests"]');
  //   var coursesGroup = $('.product-group[data-product-group="courses"]');
  //   testsGroup.css('height','auto'); //solve for all you browser stretchers out there!
  //   coursesGroup.css('height','auto'); //solve for all you browser stretchers out there!
  //   if(!$('body').hasClass('max599')) {
  //     setEqualHeight(testsGroup, true, false);
  //     setEqualHeight(coursesGroup, true, false);
  //   }
  // }, 500);
  // headerEqHeights();
  // $(window).on('load resize', function(){
  //   headerEqHeights();
  // });

  // Fancyselect

  //$('#select-cat').fancySelect();

  //Product category filter

  // var $container = $('.product-list .row');
  // filters = {};
  // $container.isotope({
  //     itemSelector: '.product'
  // });

  // filter buttons

  /*
   * TODO: Create seperate js file for archive page
   */

  // if ($(".woocommerce .woocommerce-error, .woocommerce .woocommerce-info, .woocommerce .woocommerce-message").size()){
  //  notification(".woocommerce .woocommerce-error, .woocommerce .woocommerce-info, .woocommerce .woocommerce-message");
  // }
  if ($(".notification-wrapper").length) {
    notification(".notification-wrapper");
  }

  $(".woocommerce-remove-coupon").text("[X]");

  // $(".test-features li").wrapInner('<p></p>');

  // Check if mouse is present or not
  // var mouseDetected = false;
  function onMouseMove() {
    window.removeEventListener("mousemove", onMouseMove, false);
    // mouseDetected = true;
    initializeMouseBehavior();
  }
  window.addEventListener("mousemove", onMouseMove, false);

  function initializeMouseBehavior() {
    $("body").addClass("mouse-present");
  }

  /*
  Set paddings and margins for all band-over's on the page
  */

  // function findUpTag(el, tag) {
  //     while (el.parentNode) {
  //         el = el.parentNode;
  //         if (el.tagName === tag)
  //             if (el.tagName.parentNode === "BODY")
  //                 return el;
  //     }
  //     return null;
  // }
  // var el = document.getElementsByClassName('band-over-block')[0];  // start element
  // var a = findUpTag(el, "DIV");   // search <a ...>
  // if (a) console.log(a);

  var bottomMarginOffset;

  var bandParent;
  var bandParentTop;
  var bandHeight;
  var verticalCenter = true;

  var bands = document.getElementsByClassName("band-over-block");
  function alginBandOver() {
    for (var i = 0; i < bands.length; i++) {
      var band = bands[i];
      if ($(band).hasClass("no-band-margin")) {
        bottomMarginOffset = 0;
      } else {
        bottomMarginOffset = 130;
      }
      bandParent = $(band).closest(".bo-parent");
      bandParentTop = bandParent.prev();
      if (verticalCenter) {
        bandHeight = band.clientHeight;
      } else {
        bandHeight = 80;
      }
      $(band)
        .css({
          "margin-top": -bandHeight / 2 /*,
        'margin-bottom': bottomMarginOffset*/,
        })
        .addClass("margin-bottom-" + bottomMarginOffset);
      bandParentTop.css("padding-bottom", bandHeight / 2);
      // console.log(bandParentBottom);
    }
  }

  var alginBandOverONRESIZE = debounce(function () {
    alginBandOver();
  }, 250);

  // Hamburger Menu .top-nav.ham-nav
  $(".hamburger").on("click", function () {
    if (!$("body").hasClass("hamburger-active")) {
      $("body").addClass("hamburger-active");

      if ($("body").hasClass("max767")) {
        $(".nav-links-container").css(
          "margin-top",
          parseInt($(".nav-col").outerHeight() + $(".top-banner").outerHeight())
        );
      }
    } else {
      $("body").removeClass("hamburger-active");

      $(".nav-links-container").css("margin-top", "");
    }
  });

  // Set header to primary nav if secondary nav is not present
  var header, headerFaux;
  header = $(".header");
  headerFaux = $(".header--faux");

  /**
   * Sticky nav(When the header is not the top most element)
   */
  function stickyNav2() {
    var st = $(window).scrollTop();
    // if(st > headerFaux.offset().top) {
    headerFaux.css("height", header.outerHeight());
    header.addClass("stickyNav");
    // } else {
    //  header.removeClass('stickyNav');
    //  headerFaux.css('height', '');
    // }
  }
  $(window).resize(function () {
    stickyNav2();
  });

  /**
   * Show Navigation on scroll
   */
  // Hide Header on on scroll down
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = header.outerHeight();

  $(window).on("scroll", function () {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = $(window).scrollTop();
    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      header.removeClass("nav-down").addClass("nav-up");
    } else {
      // Scroll Up
      if (st + $(window).height() <= $(document).height()) {
        header.removeClass("nav-up").addClass("nav-down");
      }
    }

    // Move Sticky band over on FAQ when nav sticks
    if ($("body").hasClass("page-template-page-faq")) {
      if (st > $(".faq-hero-container #sticky-anchor").offset().top) {
        $(".faq-main-container .band-over-block").addClass("sticky");
      } else {
        $(".faq-main-container .band-over-block").removeClass("sticky");
      }

      if (
        st > lastScrollTop &&
        st > $(".faq-main-container .band-over-block").outerHeight()
      ) {
        // Scroll Down
        $(".faq-main-container .band-over-block").css("top", "");
      } else {
        // Scroll Up
        if (
          st < lastScrollTop &&
          st > $(".faq-hero-container #sticky-anchor").offset().top
        ) {
          $(".faq-main-container .band-over-block").css("top", header.height());
        } else {
          $(".faq-main-container .band-over-block").css("top", "");
        }
      }
    }

    stickyNav2();

    lastScrollTop = st;
  }

  // Spinner js
  function updateSpinner(obj) {
    var spinnerObj = $(obj).find("input");
    var value = parseInt(spinnerObj.val());
    // var value;
    spinnerObj.on("input", function () {
      value = parseInt(spinnerObj.val());
      if (value < 0 || spinnerObj.val() === "") {
        value = 0;
        spinnerObj.val(value);
      }
    });

    $(obj)
      .find(".controls#plus")
      .click(function () {
        value++;
        $(spinnerObj).val(value);
        spinnerObj.trigger("change");
      });
    $(obj)
      .find(".controls#minus")
      .click(function () {
        if (value <= 1) {
          value = 1;
        } else {
          value--;
        }
        $(spinnerObj).val(value);
        spinnerObj.trigger("change");
      });
  }

  $(".spinner").each(function () {
    updateSpinner(this);
  });

  var navListItems = $("ul.setup-panel li a"),
    allWells = $(".setup-content");

  allWells.hide();

  navListItems.click(function (e) {
    e.preventDefault();
    var $target = $($(this).attr("href")),
      $item = $(this).closest("li");

    if (!$item.hasClass("disabled")) {
      navListItems.closest("li").removeClass("active");
      $item.addClass("active");
      allWells.hide();
      $target.show();
    }
  });

  $("ul.setup-panel li.active a").trigger("click");

  // DEMO ONLY //
  $("#activate-step-2").on("click", function (e) {
    $("ul.setup-panel li:eq(1)").removeClass("disabled");
    $('ul.setup-panel li a[href="#step-2"]').trigger("click");
    $(this).remove();
  });

  function stickyFooter(element) {
    var windowHeight = $(window).height();
    var topFooter;
    if (!$(".footer-top-container").hasClass("fhide-footer-top-container")) {
      topFooter = parseInt($(".footer-top-container").outerHeight());
    } else {
      topFooter = 0;
    }
    var otherHeights =
      parseInt($(".header").outerHeight()) +
      parseInt($(".footer").outerHeight()) +
      parseInt($(".withlovebd").outerHeight() + topFooter);
    $(element).css(
      "min-height",
      parseInt(windowHeight) - parseInt(otherHeights)
    );
  }

  // Checkout page js
  var checkoutFooter = debounce(function () {
    stickyFooter(".woocommerce-checkout .default-container");
  }, 250);
  stickyFooter(".woocommerce-checkout .default-container");
  window.addEventListener("resize", checkoutFooter);

  var checkoutFooterBlogWrapper = debounce(function () {
    stickyFooter(".blog-wrapper");
  }, 250);
  stickyFooter(".blog-wrapper");
  window.addEventListener("resize", checkoutFooterBlogWrapper);

  // 404 page js
  var checkoutFooterErrorContainer = debounce(function () {
    stickyFooter(".error-container");
  }, 250);
  stickyFooter(".error-container");
  window.addEventListener("resize", checkoutFooterErrorContainer);

  // Media queries | Enquirejs
  var maxQuery_991 = "screen and (max-width: 991px)",
    maxHandle_991 = {
      setup: function () {
        $("body").addClass("max991");
      },
      match: function () {
        $("body").addClass("max991");
        enable_991_responsive();
      },
      unmatch: function () {
        $("body").removeClass("max991");
        disable_991_responsive();
      },
      deferSetup: true,
    };
  enquire.register(maxQuery_991, maxHandle_991);

  function enable_991_responsive() {
    if ($("body").hasClass("single-product")) {
      verticalCenter = false;
    }
  }
  function disable_991_responsive() {
    if ($("body").hasClass("single-product")) {
      verticalCenter = true;
    }
  }

  var maxQuery_767 = "screen and (max-width: 767px)",
    maxHandle_767 = {
      setup: function () {
        $("body").addClass("max767");
      },
      match: function () {
        $("body").addClass("max767");
        enable_767_responsive();
      },
      unmatch: function () {
        $("body").removeClass("max767");
        disable_767_responsive();
      },
      deferSetup: true,
    };
  disable_767_responsive();
  enquire.register(maxQuery_767, maxHandle_767);

  function enable_767_responsive() {
    // Footer Accordian
    // enableAccordian('#footer-accordion');
  }
  function disable_767_responsive() {
    // Footer Accordian
    // disableAccordian('#footer-accordion');

    // Navigation CSS
    $("body").removeClass("hamburger-active");
    $(".nav-links-container").css("margin-top", "");
  }

  var maxQuery_600 = "screen and (max-width: 599px)",
    maxHandle_600 = {
      setup: function () {
        $("body").addClass("max599");
      },
      match: function () {
        $("body").addClass("max599");
        enable_600_responsive();
      },
      unmatch: function () {
        $("body").removeClass("max599");
        disable_600_responsive();
      },
      deferSetup: true,
    };

  disable_600_responsive();
  enquire.register(maxQuery_600, maxHandle_600);

  function enable_600_responsive() {
    // enableAccordian('#header-accordion');
  }
  function disable_600_responsive() {
    // disableAccordian('#header-accordion');
  }

  // var footerAccordion;
  function enableAccordian(element) {
    $(element)
      .find(".collapse-group")
      .each(function () {
        var self = this;
        // setTimeout(function(){
        $(this).find(".collapse").slideUp();

        // }, 400);
        $(this)
          .find(".collapse-toggle")
          .on("click", function (e) {
            e.preventDefault();
            if (
              $(this).parents().find(self).find(".collapse").css("display") ===
              "block"
            ) {
              $(element)
                .find(".collapse-group .collapse")
                .not($(this).parents().find(self).find(".collapse"))
                .slideUp();
            } else {
              $(element).find(".collapse-group .collapse").slideUp();
            }
            // console.log($('#footer-accordion .collapse-group').find('.collapse').length)
            $(this).parents().find(self).find(".collapse").slideToggle();
          });
      });

    // console.log($(element).find('.collapse-group:first-child .collapse').html())
    $(element).find(".collapse-group:first-child .collapse").slideDown();
  }

  function disableAccordian(element) {
    $(element)
      .find(".collapse-group")
      .each(function () {
        $(this).find(".collapse").slideDown();
        // $(this).find('.collapse-toggle').on('click', function(e){
        //  e.preventDefault();
        // });
        $(this).find(".collapse-toggle").off("click");
        // $(element).preventDefault();
      });
  }

  var maxQuery_480 = "screen and (max-width: 480px)",
    maxHandle_480 = {
      setup: function () {
        $("body").addClass("max480");
      },
      match: function () {
        $("body").addClass("max480");
        enable_480_responsive();
      },
      unmatch: function () {
        $("body").removeClass("max480");
        disable_480_responsive();
      },
      deferSetup: true,
    };
  enquire.register(maxQuery_480, maxHandle_480);

  function enable_480_responsive() {
    if (!$("body").hasClass("single-product")) {
      verticalCenter = false;
    }
  }
  function disable_480_responsive() {
    if (!$("body").hasClass("single-product")) {
      verticalCenter = true;
    }
  }

  // The functions are run here so that the variables are initialized based on the media queries.
  // Eg: verticalCenter is set to false below 480px. If the function runs before the variable is set then it will fire the wrong statement.
  alginBandOver();
  window.addEventListener("resize", alginBandOverONRESIZE);

  // Widow word fix for products in header and footer
  $(
    "#footer-accordion .footer-links a, #header-accordion .dropdown-links a"
  ).widowFix();

  // Widow fix for all product cards
  $(
    ".product-block:not(.product_collection-courses) .product-name h4"
  ).widowFix({
    letterLimit: 4,
  });

  // On product inside page title
  $(".ph-l .main-product-page-title").widowFix({
    letterLimit: 4,
  });

  // On cart page
  $(".cart_item .product-name p a").widowFix();

  // On product breadcrumbs
  $(".pretest-wc-breadcrumb").widowFix();

  // Anchor Smooth scrolling
  jQuery(function () {
    jQuery('a[href*="#"].anchor-scroll:not([href="#"])').click(function () {
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        var target = jQuery(this.hash);
        target = target.length
          ? target
          : jQuery("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          jQuery("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000
          );
          return false;
        }
      }
    });
  });

  // Stamped.io reviews
  // Open Stamped.io side drawer when clicked on reviews badge in footer
  $(".stamped-reviews-widget-wrapper").on("click", function (event) {
    event.preventDefault();
    setTimeout(function () {
      $(".stamped-widget-button").trigger("click");
    }, 350);
  });
});
