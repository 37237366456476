jQuery(document).ready(function ($) {
  let timeout;

  $(".products-drop-down").on("mouseover", function () {
    //clear old timeout if hovered again
    clearTimeout(timeout);
    $(this).addClass("products-drop-down-active");
  });

  $(".products-drop-down").on("mouseleave", function () {
    timeout = setTimeout(
      () => $(this).removeClass("products-drop-down-active"),
      400
    );
  });

  $(".category .wrapper").on("mouseenter", function (e) {
    if ($(e.target).hasClass("flex-wrapper") && $(window).width() > 768) {
      this.click();

      // remove previously selected item
      $(".category .wrapper").not(this).removeClass("active-category");

      // toggle active-category class
      $(this).addClass("active-category");
    }
  });

  $(".category .wrapper").on("click", function (e) {
    if ($(e.target).hasClass("flex-wrapper")) {
      // remove previously selected item
      $(".category .wrapper").not(this).removeClass("active-category");

      if ($(window).width() < 768) {
        // toggle active-category class
        $(this).toggleClass("active-category");
      } else {
        // route to page
        location.href = $(this).attr("data-route");
      }
    }
  });

  // select first category by default
  $(window).on("load", function () {
    $(".menu-container .sidebar .category .wrapper")
      .first()
      .addClass("active-category");

    $(".category-card-row .category-details-wrapper")
      .first()
      .addClass("active");
  });

  // accordion
  $(window).on("load resize", function () {
    if ($(this).width() < 768) {
      $(".category .wrapper").removeAttr("data-toggle");
    } else {
      $(".category .wrapper").attr("data-toggle", "tab");
    }
  });
});
